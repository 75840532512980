<template>
  <div class="search-filters">
    <div class="search-filter search-filter-multiselect is-expanded">
      <div class="filter-header">
        <div class="filter-expander my-2" variant="link" block="block">
          {{ $T('SupplierFilterTitle') }}
        </div>
      </div>
      <transition-expand>
        <div class="filter-body">
          <div class="filter-controls">
            <div class="controls-meta">
              <b-form-row class="justify-content-between align-items-center">
                <b-col cols="auto">
                  <button class="btn btn-link" @click="setAllSupplierFilters"> {{ $T('MetaSelectAll') }}</button>
                </b-col>
                <b-col cols="auto">
                  <button id="resetFiltersButton" class="btn btn-link" @click="resetSupplierFilters" :disabled="!selectedSupplier.length"> {{ $T('MetaSelectNone') }}</button>
                </b-col>
              </b-form-row>
            </div>
            <div class="controls-control" v-for="supplier in supplierFilter" :key="supplier.Item1">
              <div role="group" class="form-group control-checkbox">
                <div>
                  <div class="custom-control custom-checkbox">
                    <input :id="`filter_${supplier.Item1}`" type="checkbox" class="custom-control-input" :checked="selectedSupplier.includes(supplier.Item1)" @change="onFilterClick($event, supplier)" />
                    <label :for="`filter_${supplier.Item1}`" class="custom-control-label">
                      <span class="control-text"><span>{{ supplier.Item1 }}</span></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="control-count">{{ supplier.Item2 }}</div>
            </div>
          </div>
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import url from '@/assets/js/helper/url'
export default {
  name: 'OfferFilter',
  props: {
    selectedSupplier: {
      type: Array,
      required: true
    },
    supplierFilter: {
      type: Array,
      required: true
    }
  },
  methods: {
    onFilterClick (event, supplier) {
      if (event.target.checked) {
        location.href = url.buildUrl(window.location.href, 'su', supplier.Item1)
      } else {
        location.href = url.removeParameterFromUrl(window.location.href, 'su', supplier.Item1)
      }
    },
    resetSupplierFilters () {
      location.href = url.removeParameterFromUrl(window.location.href, 'su')
    },
    setAllSupplierFilters () {
      const suppliers = this.supplierFilter.map(supplier => supplier.Item1).join(';')
      location.href = url.buildUrl(window.location.href, 'su', suppliers)
    }
  }
}
</script>
